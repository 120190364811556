// Here you can add other styles
.avatar {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, 0.2);
}
.menuTop {
  display: flex;
  justify-content: space-between;
}
.navigation {
  display: flex;
  flex-direction: row;
  //   justify-content: space-evenly;
  align-items: center;
}
.logo {
  width: 30px;
}
.TitlePhalanx {
  margin-top: 8px;
  margin-right: 25px;
  font-family: roboto;
}
.titleInDesing {
  text-align: center;
}
.productBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btnStyle {
  // min-height: 40;
  // min-width: 95;
  width: 100%;
  font-size: 16px;
  background-color: white;
  border: 1px solid silver;
  box-shadow: 1px 1px 1px 1.5px silver;
  text-transform: none;
  color: black;
  margin: 2vh 1vh;
  border-radius: 3px;
  font-family: roboto;
}
.btnStyle:hover {
  background-color: #87ceeb;
  border: 1px solid #87ceeb;
  color: black;
  font-family: roboto;
}
.SelectStyle {
  // min-height: 40;
  // min-width: 95;
  // width: 210px;
  text-align: center;
  border-radius: 3px;
  margin: 2vh 1vh;
}
.tablePricing {
  display: flex;
  justify-content: center;
}
.tablePrix {
  width: 50%;
}
.tableTrPrix {
  border: 1px solid black;
  width: 40%;
}
.backPrice {
  height: 100%;
  background-color: transparent;
  border-color: transparent;
}
.ListLeftNav {
  color: black;
  font-family: roboto;
}
// .sidebar:not(.sidebar-end) ~ * {
//   --cui-sidebar-occupy-start: 12.5rem;
// }
.sidebar {
  // --cui-sidebar-width: 14rem !important;
  --cui-sidebar-bg: white !important;
  --cui-sidebar-nav-group-bg: transparent !important;
  // --cui-sidebar-nav-link-hover-bg: #6610f2 !important;
  --cui-sidebar-nav-link-hover-bg: #87ceeb !important;
  // width: 60px;
}
.navSideReduit:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 3rem;
}
.navSideReduit {
  width: 65px;
}
.sidebar-nav .nav-title {
  margin-top: 0px;
  margin-left: 8px;
  font-size: 17px;
  color: black;
}
.header-toggler {
  color: black;
}
.header {
  --cui-header-padding-x: 0px;
}
.body {
  // background-color: rgb(239, 247, 244);
  background-color: transparent;
}
.headerColor {
  background-color: #87ceeb;
  // background-color: #6610f2;
}
.logoTitle {
  background-color: #87ceeb;
  // background-color: #6610f2;
  color: white;
}
.backNavLeft {
  // background-color: silver;
  background-color: white;
  // border-right: 1px solid silver;
}
.sidebar-nav .nav-link {
  color: black;
  font-family: roboto;
}
.sidebar-nav .nav-link:hover {
  // color: #87ceeb;
  font-weight: bold;
  // width: 180px;
  border-radius: 5px;
  // margin: 1vh;
}
.sidebar-nav .nav-icon {
  color: black;
}
.sidebar-nav .nav-link.active .nav-icon {
  color: black !important;
  font-weight: bold;
}
.sidebar-nav .nav-link.active {
  color: black;
  font-weight: bold;
  // background-color: silver;
  // width: 180px;
  border-radius: 5px;
  // margin: 1vh;
}
.sidebar-nav .nav-group.show .nav-group-toggle {
  color: black !important;
  font-family: roboto;
}
.logoBTC {
  width: 40px;
  margin-right: 10px;
}
.logoETH {
  width: 40px;
  margin-right: 10px;
}
.priceBTC {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.containerTrade {
  // background-color: #87ceeb;
  display: flex;
  justify-content: center;
  // align-items: center;
}
.centerTable {
  // background-color: #87ceeb;
  text-align: center;
}
.centerPriceGreen {
  background-color: #34c924;
  text-align: center;
}
.mb-4 {
  margin-bottom: 0rem !important;
}
.Container {
  height: 100%;
  background-color: transparent !important;
  border-color: transparent !important;
}
.divided {
  display: flex;
  align-items: center;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin: 5px;
}
.PseudoPolicy {
  font-family: roboto;
  color: white;
}
.textPolicy {
  font-family: roboto;
  color: black;
}
.titleLeftDesign {
  display: flex;
  // justify-content: center;
  align-items: center;
}
.chart-wrapper {
  height: 17rem !important;
}
.ContainerChart {
  height: 18em;
  background-color: transparent !important;
  border-color: transparent !important;
}
// .sidebar-nav .nav-group-toggle::after {
//   background: black !important;
// }
.container {
  max-width: none;
}

// STYLE LIST USERS
.filterUser {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.inputFilter {
  width: 240px;
  min-width: 240px;
}
.widthTable {
  min-width: 170;
}
.btnActivUser {
  border-radius: 50%;
  width: 10px;
  height: 25px;
}
.colorIcon {
  color: white;
}
.btnAdd {
  // display: flex;
  // justify-content: flex-end;
  margin-right: 30px;
}
.btnArchive {
  // display: flex;
  // justify-content: flex-end;
  margin-left: 30px;
}
.notifAlready {
  color: red;
  text-align: center;
}
.intitulDetail {
  // text-decoration: underline;
  font-size: 18px;
  font-weight: bold;
}

// STYLE PORTFOLIO
.tableWidth {
  min-width: 70;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  background-color: silver !important;
}
.MuiFormControlLabel-root {
  margin-top: 18px;
}

.btnSaveScope {
  background-color: #34c924;
  border-color: #34c924;
  color: black;
  min-width: 120px;
}
.btnSaveScope:hover {
  background-color: greenyellow;
  border-color: greenyellow;
  color: black;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
// linear-gradiant(#F1AE00,#fggg,#vvhh,#ghjj)

/* Styles pour la légende personnalisée */
.custom-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 5px; /* Ajoutez un espace entre le graphique et la légende */
  // margin-left: 20px; /* Ajoutez un espace entre le graphique et la légende */
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Espacement entre les éléments de légende */
}

.legend-icon {
  display: inline-block;
  width: 30px; /* Largeur du tiret */
  height: 6px; /* Hauteur du tiret */
  background-color: rgb(26, 141, 26); /* Couleur du tiret */
  margin-right: 5px; /* Espacement entre l'icône et le label */
}

.legend-icon2 {
  display: inline-block;
  width: 30px; /* Largeur du tiret */
  height: 6px; /* Hauteur du tiret */
  background-color: #f13724; /* Couleur du tiret */
  margin-right: 5px; /* Espacement entre l'icône et le label */
}

.legend-label {
  font-size: 14px;
}

.ag-root-wrapper.ag-layout-normal {
  height: 350px !important;
  margin: 7px;
}
